import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DatePicker, { CalendarContainer } from 'react-datepicker';
import Moment from 'react-moment';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';

import { provideContext } from '../../../../context/context.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import Button from '../../button/button.jsx';
import Message from '../../presentation/message/message.jsx';
import * as apiRentalSelectors from '../../../../redux-api/apiRental/apiRentalSelectors.js';
import * as apiUsersSelector from '../../../../redux-api/apiUsers/apiUsersSelectors.js';
import { Trans } from 'react-i18next';
import { apiGetUserMeRequestAction } from '../../../../redux-api/apiUsers/apiUsersActions.js';
import { bindActionCreators } from 'redux';

import './overviewPanel.scss';

class OverviewPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      shippingDate: moment(props.shippingDate),
      canBeEdited: true,
    };
  }

  async componentWillMount() {
    this.props.actions.getUser();
  }

  handleChangeShippingDate = newDate => {
    const { context, rental } = this.props;
    this.setState({ isOpen: false, shippingDate: moment(newDate) });
    this.props.apiUpdateShippingDate({
      rentalId: rental._id,
      shippingDate: moment(newDate).format('YYYY-MM-DD'),
    });
  };

  handleCancelRentalClick = () => {
    const { rental } = this.props;
    this.props.showCancelOrderModal(rental);
  };

  handleEditAddressClick = () => {
    const { rental } = this.props;
    this.props.showEditAddressModal(rental);
  };

  openCalendar = event => {
    this.setState({ isOpen: true });
  };

  closeCalendar = event => {
    this.setState({ isOpen: false });
  };

  renderError = errors => {
    return (
      <div className="order-status-overview-error">
        <Message type="error">{errors.error.message}</Message>
      </div>
    );
  };

  filterDate = date => {
    const { shippingDateEditRange } = this.props.order.rental;
    shippingDateEditRange.disabledDates = shippingDateEditRange.disabledDates || [];
    return (
      ![0, 6].includes(date.getDay()) &&
      moment(date) >= moment(shippingDateEditRange.minDate) &&
      moment(date) <= moment().add(4, 'months') &&
      !shippingDateEditRange.disabledDates.includes(moment(date).format('YYYY-MM-DD'))
    );
  };

  render() {
    const {
      t,
      context,
      userData,
      title,
      iconName,
      showButton,
      showShippingDate,
      itemData,
      order,
      successShippingDateUpdate,
      errorShippingDateUpdate,
    } = this.props;

    const { rental } = order;

    const{ givenName, surname } = this.props.user;
    const { firstName, lastName, address1, address2, city, zip, province, country } = rental.shippingAddress;

    const userFullName = !givenName || !surname ? null : `${givenName} ${surname}`;
    const fullName = `${firstName} ${lastName}`;
    const shippingAddress = `${address1}, ${address2 ? address2 + ', ' : ''}${city} ${zip}, ${
      province ? province + ',' : ''
    } ${country}`;

    return (
      <div className="OverviewPanel">
        <div className="edit-profile-overview">
          {rental && rental.shippingAddressCanBeEdited && (
            <button className="edit-icon" onClick={this.handleEditAddressClick}>
              <FontAwesomeIcon icon={faEdit} size={'lg'} />
            </button>
          )}
          <div className="item">
            <h4>{t('overview_panel_name', 'Name')}</h4>
            <p>{userFullName || fullName}</p>
          </div>
          <div className="item">
            <h4>{t('global_email')}</h4>
            <p className="email">{userData.email}</p>
          </div>
          <div className="item">
            <h4>{t('global_shipping_address', 'Shipping address')}</h4>
            <p>{shippingAddress}</p>
          </div>
        </div>
        <div className="order-status-overview">
          <div className="icon-wrap">
            <div className="icon-center">
              <div className="icon active">
                <div className="circle">
                  <img src={iconName} className="active" alt="" />
                </div>
                <div className="label">
                  <h4>{title}</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="details-wrap">
            <div className="details-center">
              <div className="details">
                {itemData && itemData.map((item, index) => {
                  return (
                    <div key={index} className="details-item">
                      <h4>{item.title}</h4>
                      <p>{item.value}</p>
                    </div>
                  );
                })}

                {showShippingDate && (
                  <div className="details-item">
                    <h4>{t('overview_panel_shipping_date_title', 'Shipping date')}</h4>
                    {!isEmpty(successShippingDateUpdate) && successShippingDateUpdate._id === rental.id && (
                      <p className="success-message">
                        {t(
                          'overview_pannel_shipping_date_changed_success',
                          'You have successfully changed the shipping date.',
                        )}
                      </p>
                    )}
                    {!isEmpty(errorShippingDateUpdate) && (
                      <p className="error-message">
                        {t('overview_panel_shipping_date_not_changed', 'You did not change shipping date')}
                      </p>
                    )}
                    <p>
                      <span>
                        <Moment format={context.apiConfig.date.format} date={this.state.shippingDate} />
                      </span>
                      {rental && rental.canBeEdited && (
                        <button className="edit-icon" onClick={this.openCalendar}>
                          <FontAwesomeIcon icon={faEdit} />
                        </button>
                      )}
                    </p>
                    <p className="shipping-date-disclaimer">
                    <Trans i18nKey="overview_panel_shipping_date_allow_two_to_five">
                      Ships within 4 business days of the date you pick. Allow an
                      {' '}
                      <i>additional</i>
                      {' '}
                      2-5 business days for SNOO to arrive.
                    </Trans>
                    </p>
                  </div>
                )}
                {showButton && order && (
                  <div className="details-item">
                    <Button color={'green'} to={`${context.appRoutes.BILLS.url}/${order._id}/${order.currentPeriod}`}>
                      {t('button_text_go_to_bill_history', 'Go to Bill History')}
                    </Button>
                  </div>
                )}
                {rental && rental.canBeEdited && (
                  <div className="details-item">
                    <button className="danger-text cancel-order-action" onClick={this.handleCancelRentalClick}>
                      {t('button_text_cancel_order', 'Cancel order')}
                    </button>
                  </div>
                )}
              </div>
              {this.state.isOpen && (
                <DatePicker
                  inline
                  withPortal
                  onClickOutside={this.closeCalendar}
                  onChange={this.handleChangeShippingDate}
                  selected={this.state.shippingDate.toDate()}
                  filterDate={this.filterDate}
                  calendarContainer={DatePickerContainer}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function DatePickerContainer({ className, children }) {
  return (
    <CalendarContainer className={className}>
      <div>{children}</div>
      <div className="date-picker-footer">
        <p>
          <Trans i18nKey="overview_panel_shipping_date_allow_two_to_five">
          Ships within 3 business days of the date you pick. Allow an
          {' '}
          <i>additional</i>
          {' '}
          2-5 business days for SNOO to arrive.
        </Trans>
        </p>
      </div>
    </CalendarContainer>
  );
}

const mapStateToProps = state => ({
  successShippingDateUpdate: apiRentalSelectors.getUpdateShippingDateSuccess(state),
  errorShippingDateUpdate: apiRentalSelectors.getUpdateShippingDateErrors(state),
  user: apiUsersSelector.getUser(state),
});

const mapDispatchToProps = dispatch => {
  const actions = bindActionCreators(
    {
      getUser: apiGetUserMeRequestAction,
    },
    dispatch,
  );
  return { actions };
};

OverviewPanel.propTypes = {
  t: PropTypes.func.isRequired,
  userData: PropTypes.object.isRequired,
  shippingDate: PropTypes.string,
  showCancelOrderModal: PropTypes.func,
  showEditAddressModal: PropTypes.func,
};

export default provideContext(connect(mapStateToProps, mapDispatchToProps)(OverviewPanel));
