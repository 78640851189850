import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '../../button/button.jsx';
import { provideContext } from '../../../../context/context.jsx';
import Input from '../../input/input.jsx';
import { Auth } from 'aws-amplify';
import Message from "../../presentation/message/message";
import { redirectToLogin, redirectToLogout } from '../../../../utils/redirect';
import { globalSignOut } from '../../../../redux-api/apiUsers/api';
import withFocusTrap from '../../../withFocusTrap';

class ConfirmEmailChangeModal extends Component {
  state = {
    confirmationCode: '',
    error: null,
  };

  handleConfirmationCodeChange = event => {
    this.setState({ confirmationCode: event.target.value });
  };

  handleFormSubmit = async event => {
    event.preventDefault();
    const { confirmationCode } = this.state;

    if (!confirmationCode) {
      this.setState({ error: 'Please enter the confirmation code' });
      return;
    }

    try {
      await Auth.verifyCurrentUserAttributeSubmit('email', confirmationCode);
      this.props.onSubmitForm();
      globalSignOut().then(() => {
          redirectToLogin();
        })
        .catch(error => {
          console.error(error);
        });
      this.props.onCloseModal();
    } catch (error) {
      this.setState({ error: error.message });
    }
  };

  render() {
    const { t, onCloseModal, modalRef } = this.props;
    const { error } = this.state;

    return (
      <div ref={modalRef} className="ConfirmModal" onClick={onCloseModal}>
        <div className="modal-center" onClick={e => e.stopPropagation()}>
          <div className="inner-modal-center">
            <div className="modal-text">
              {t('confirm_modal_change_email_heading', `Please enter the confirmation code that we've sent to your new email address to complete the email change process.`)}
              <Input
                required
                id="confirmationCode"
                name="confirmationCode"
                type="text"
                placeholder={t('confirmation_code', 'Confirmation Code')}
                onChange={this.handleConfirmationCodeChange}
              />
            </div>
            <div className="button-wrap">
              <Button color={'green'} onClick={this.handleFormSubmit}>
                {t('confirm_modal_change_email', 'Yes, change email address')}
              </Button>
              <Button onClick={onCloseModal} color="green-inverted">
                {t('global_no', 'No')}
              </Button>
            </div>
            {error && <Message type="modal-center">{error}</Message>}
          </div>
        </div>
      </div>
    );
  }
}

ConfirmEmailChangeModal.propTypes = {
  t: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onSubmitForm: PropTypes.func.isRequired,
};

export default provideContext(withFocusTrap(ConfirmEmailChangeModal));
