import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '../../button/button.jsx';
import { provideContext } from '../../../../context/context.jsx';

import Message from '../../presentation/message/message.jsx';
import subscriptionStopedActive from '../../../../assets/icons/subscription-stoped-active.svg';

import './cancelOrderModal.scss';
import withFocusTrap from '../../../withFocusTrap';

class CancelOrderModal extends Component {
  renderError(error) {
    return <Message type="error">{error.message}</Message>;
  }

  render() {
    const {
      t,
      onCloseModal,
      onCancelOrder,
      isLoadingCancelRental,
      errors,
      modalRef,
    } = this.props;

    return (
      <div ref={modalRef} className="CancelOrderModal" onClick={onCloseModal}>
        <div className="modal-center" onClick={e => e.stopPropagation()}>
          <div className="inner-modal-center">
            <div className="icon-wrap">
              <span className="icon">
                <img src={subscriptionStopedActive} alt="" />
              </span>
            </div>
            <h3>{t('cancel_order_modal_question','Are you sure you want to cancel your order?')}</h3>
            <p className="modal-text">
              {t(
                'cancel_order_modal_info','This will cancel ALL items in your order. If you change your mind and want to rent SNOO, you will need to place a new order.',
              )}
            </p>
            <div className="button-wrap">
              <Button onClick={onCloseModal} color={'green-inverted'}>
                {t('cancel_order_modal_changed_my_mind','No, I have changed my mind')}
              </Button>
              <Button color={'green'} onClick={onCancelOrder} disabled={isLoadingCancelRental}>
                {t('cancel_order_modal_confirm','Yes, cancel my order')}
              </Button>
            </div>
            {errors && (
              <div className="modal-errors">{this.renderError(errors)}</div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

CancelOrderModal.propTypes = {
  t: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onCancelOrder: PropTypes.func.isRequired,
  isLoadingCancelRental: PropTypes.bool,
  errors: PropTypes.object,
};

export default provideContext(withFocusTrap(CancelOrderModal));
