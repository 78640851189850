import './benefitsDisplay.scss';
import CompanyImage from './companyImage';
import { useTranslation } from "react-i18next";

const generateItems = (benefits, rentalPrice, t) => {
  const {
    benefitsMonthsLength,
    chargeReturnShippingFee,
    chargeReconditioningFee,
    chargeDeposit,
    chargeEmployeeForSNOORental,
    corporateRentalPrice
  } = benefits;
  const employeeCoversReturnShippingFee = chargeReturnShippingFee === "Employee";
  const employeeCoversReconditioningFee = chargeReconditioningFee === "Employee";

  const chargeDepositCompany = chargeDeposit === 'None' || chargeDeposit === 'Company';
  const companyCoversAll = !employeeCoversReconditioningFee && !employeeCoversReturnShippingFee && chargeDepositCompany;

  const benefitsItems = [
    chargeEmployeeForSNOORental ?
      t('benefit_item_snoo_with_discount', `$${rentalPrice - corporateRentalPrice} discount on a ${benefitsMonthsLength} months SNOO rental`) :
      t('benefit_item_snoo_100_off',  `${benefitsMonthsLength} months of SNOO rental fee`),
    !employeeCoversReconditioningFee && t('charge_reconditioning_fee','Reconditioning fee'),
    !employeeCoversReturnShippingFee && t('snoo_account_overview_return_shipping_fee'),
    chargeDepositCompany && t('charge_security_deposit','Refundable security deposit'),
  ].filter(Boolean);

  const responsibilitiesItems = companyCoversAll
    ? [t('benefit_all_off','Nothing, you are free of charge!')]
    : [
      !chargeDepositCompany && t('benefit_item_security_deposit','\$99 Security Deposit (fully refundable if SNOO is returned without beyond normal wear and tear).'),
      employeeCoversReconditioningFee && t('charge_reconditioning_fee', 'Reconditioning Fee'),
      employeeCoversReturnShippingFee && t('snoo_account_overview_return_shipping_fee')
    ].filter(Boolean);

  return {
    benefitsItems,
    responsibilitiesItems
  };
}

export default function BenefitDisplay({ corporateBenefits, rentalPrice }) {
  const { error, data } = corporateBenefits;
  const { t } = useTranslation();
  let benefits = null;

  if (error) {
    return <>{t('global.request_error', 'There was an error with your request, please try again later.')}</>;
  }

  if (data) {
    benefits = data;
  }

  if (!benefits) {
    return null;
  }

  const {
    companyImage,
    companyName,
  } = benefits;

  const { benefitsItems, responsibilitiesItems } = generateItems(benefits, rentalPrice, t);

  const benefitsAndResponsibilities = [
    {
      title: t('benefit_company_title','Your benefit includes:'),
      items: benefitsItems,
    },
    {
      title: t('benefit_item_responsibility_title','You will be responsible for:'),
      items: responsibilitiesItems,
    },
    {
      title: t('benefit_item_promo_title','Get SNOO when you need it!'),
      items: [
        t('benefit_item_promo_one','Order now and pick a ship date up to 4 months in the future.'),
        t('benefit_item_promo_two', 'SNOO will arrive 5-8 business days after the selected ship date.'),
      ],
    },
    {
      title: t('benefit_item_snoo_returning','Returning SNOO'),
      items: [
        t('benefit_item_snoo_returning_guide', 'When you are done using SNOO, just click on the RETURN tab in the top menu. SNOO must be packed and returned in the original packaging it came in. If you need to order new packaging, click on SNOO BOX.'),
      ],
    },
  ];
  return(
    <div className="col-1">
      <CompanyImage image={companyImage} />
      {
        benefits.description &&
        <div className={"benefits__description"}>
          <div className={'benefits__description__col'}>
            <h4>Description</h4>
            <p>{benefits.description}</p>
          </div>
        </div>
      }
      <div className={'benefits'}>

        {benefitsAndResponsibilities.map((benefit) => (
          <div key={benefit.title} className={'benefits__col'}>
            <h4>{benefit.title}</h4>
            <ul>
              {benefit.items.map((item, index) => (
                <li key={`${benefit.title}-${index}`}>{item}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
}
