import React from 'react';

function withFocusTrap(WrappedComponent) {
  return class FocusTrap extends React.Component {
    constructor(props) {
      super(props);
      this.modalRef = React.createRef();
      this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    componentDidMount() {
      if (this.modalRef.current) {
        this.trapFocus();
      }
      document.addEventListener('keydown', this.handleKeyDown);
    }

    componentWillUnmount() {
      document.removeEventListener('keydown', this.handleKeyDown);
    }

    trapFocus() {
      const modalElement = this.modalRef.current;

      if (modalElement) {
        const focusableElements = modalElement.querySelectorAll(
          'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
        );
        this.firstElement = focusableElements[0];
        this.lastElement = focusableElements[focusableElements.length - 1];

        this.firstElement?.focus();
      }
    }

    handleKeyDown(event) {
      if (event.key === 'Tab' && event.shiftKey && document.activeElement === this.firstElement) {
        event.preventDefault();
        this.lastElement.focus();
      } else if (event.key === 'Tab' && document.activeElement === this.lastElement) {
        event.preventDefault();
        this.firstElement.focus();
      }
    }

    render() {
      return <WrappedComponent {...this.props} modalRef={this.modalRef} />;
    }
  };
}

export default withFocusTrap;
