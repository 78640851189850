import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { provideContext } from '../../../../context/context.jsx';

import Button from '../../button/button.jsx';
import Message from '../../presentation/message/message.jsx';

import './simpleConfirmModal.scss';
import withFocusTrap from '../../../withFocusTrap';

class SimpleConfirmModal extends Component {
  renderError(error) {
    return <Message type="error">{error.error.message}</Message>;
  }


  render() {
    const { t, content, onClose, onConfirm, modalRef } = this.props;
    return (
      <div ref={modalRef} className="ConfirmModal" onClick={onClose}>
        <div className="modal-center" onClick={e => e.stopPropagation()}>
          <div className="inner-modal-center">
            <div className="modal-text">{content}</div>
            <div className="button-wrap">
              <Button onClick={onConfirm} color={'green'}>{t('button_text_yes', 'Yes')}</Button>
              <Button onClick={onClose} color={'green-inverted'}>
                {t('global_no', 'No')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SimpleConfirmModal.defaultProps = {
  content: null,
};

SimpleConfirmModal.propTypes = {
  t: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default provideContext(withFocusTrap(SimpleConfirmModal));
