import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '../../button/button.jsx';
import { provideContext } from '../../../../context/context.jsx';

import './requestAccountDeletionModal.scss';
import { createAnonymizationRequest } from '../../../../redux-api/apiUsers/api';
import Message from '../../presentation/message/message';

import ReactGA from 'react-ga4';
import { Auth } from "aws-amplify";
import { redirectToLogout } from "../../../../utils/redirect";
import withFocusTrap from '../../../withFocusTrap';

class RequestAccountDeletionModal extends Component {
  state = {
    isSubmitSuccess: false,
    hasError: false,
    loading: false,
  };
  renderError(error) {
    return <Message type="error">{error}</Message>;
  }

  handleSubmit = async () => {
    const logoutUser = () => {
      Auth.signOut()
      .then(() => {
        redirectToLogout();
      })
      .catch(error => {
        console.error(error);
      });
    };

    try {
      this.setState({ loading: true });
      const response = await createAnonymizationRequest();
      ReactGA.event({
        category: 'Button Clicked',
        action: 'user_anonymization_requested',
        label: 'User Anonymization has been requested',
      });
      if (response && response.err) {
        throw response.err;
      }
      this.setState({ isSubmitSuccess: true, hasError: false });
      logoutUser();
    } catch (error) {
      this.setState({ hasError: true });
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const { t, onCloseModal, modalRef } = this.props;
    return (
      <div ref={modalRef} className="RequestAccountDeletionModal">
        <div className="modal-center" onClick={(e) => e.stopPropagation()}>
            <>
              <h2>{t('profile_data_deletion_popup_title', 'Are you sure you want to delete your account?')}</h2>
              <div className="inner-modal-center">
                <div className="modal-text">
                  <p>
                    {t(
                      'profile_data_deletion_popup_text',
                      'Doing so will remove your account and all associated data for the Happiest Baby App and website. This cannot be undone.',
                    )}
                  </p>
                  {this.state.hasError && this.renderError(t('general_error_unexpected'))}
                </div>
                <div className="button-wrap">
                  <Button onClick={onCloseModal} color={'green-inverted'}>
                    {t('button_text_cancel')}
                  </Button>
                  <Button color={'red'} onClick={() => this.handleSubmit()}>
                    {this.state.loading
                      ? t('button_text_loading', 'Loading...')
                      : t('button_text_continue', 'Continue')}
                  </Button>
                </div>
              </div>
            </>
        </div>
      </div>
    );
  }
}

RequestAccountDeletionModal.propTypes = {
  t: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default provideContext(withFocusTrap(RequestAccountDeletionModal));
