import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { provideContext } from '../../../../context/context.jsx';
import Button from '../../button/button.jsx';
import Input from '../../input/input.jsx';
import Message from '../../presentation/message/message.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import './changePasswordForm.scss';
import { Auth } from 'aws-amplify';
import { redirectToLogin, redirectToLogout } from '../../../../utils/redirect';
import { globalSignOut } from '../../../../redux-api/apiUsers/api';

class ChangePasswordForm extends Component {
  state = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
    formErrors: null,
    showPassword: false,
    submitErrors: this.props.errorPasswordSubmit,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.changePasswordError !== state.submitErrors) {
      return {
        submitErrors: props.changePasswordError,
      };
    }
    if (props.changePasswordSuccess && !isEmpty(props.changePasswordSuccess)) {
      globalSignOut()
        .then(() => {
          redirectToLogin();
        })
        .catch(error => {
          console.error(error);
        });
    }
    return null;
  }

  onChangeForm = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  submitPassword = event => {
    this.setState({
      formErrors: null,
      submitErrors: null,
    });

    if (event) {
      event.preventDefault();
    }

    const error = this.getFormError(this.state.newPassword, this.state.confirmPassword);

    if (error) {
      this.setState({
        formErrors: { message: error },
      });
      return;
    }

    const data = {
      oldPassword: this.state.oldPassword,
      newPassword: this.state.newPassword,
    };
    this.props.apiSubmitPassword(data);
  };

  getFormError = (newPassword, confirmPassword) => {
    const { t } = this.props;

    if (newPassword !== confirmPassword) {
      return t('passwords_dont_match_error', "Passwords don't match");
    }

    return null;
  };

  renderError(error) {
    return <Message type="error">{error.localizedMessage || error.message}</Message>;
  }

  renderSuccess() {
    const { t } = this.props;
    return <Message type="success">{t('api_response_password_change_success', 'Password changed successfully!')}</Message>;
  }

  togglePasswordShow = e => {
    e.preventDefault();
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };
  toggleOldPasswordShow = e => {
    e.preventDefault();
    this.setState({
      showOldPassword: !this.state.showOldPassword,
    });
  };

  render() {
    const { t } = this.props;
    const { showPassword, showOldPassword } = this.state;

    return (
      <div className="ChangePasswordForm">
        <form onChange={this.onChangeForm}>
          <div className="row">
            <div className="col-1">
              {this.state.submitErrors && !isEmpty(this.state.submitErrors) ? (
                this.renderError(this.state.submitErrors)
              ) : this.state.formErrors && !isEmpty(this.state.formErrors) ? (
                this.renderError(this.state.formErrors)
              ) : this.props.changePasswordSuccess && !isEmpty(this.props.changePasswordSuccess) ? (
                this.renderSuccess(this.props.changePasswordSuccess)
              ) : null}
            </div>
            <div className="col-1">
              <div className="password-wrap">
                <Input
                  required
                  id="oldPassword"
                  name="oldPassword"
                  type={showOldPassword ? 'text' : 'password'}
                  autoComplete="off"
                  placeholder={t('input_placeholder_password_old', 'Current Password')}
                  value={this.state.oldPassword}
                />
                <span onClick={this.toggleOldPasswordShow} className="eye-password">
                  {showOldPassword ? (
                    <FontAwesomeIcon icon={faEyeSlash} className="eye-icon" />
                  ) : (
                    <FontAwesomeIcon icon={faEye} className="eye-icon" />
                  )}
                </span>
              </div>
              <div className="password-wrap">
                <Input
                  required
                  id="newPassword"
                  name="newPassword"
                  type={showPassword ? 'text' : 'password'}
                  autoComplete="off"
                  placeholder={t('input_placeholder_password_new', 'New Password')}
                  value={this.state.newPassword}
                />
                <span onClick={this.togglePasswordShow} className="eye-password">
                  {showPassword ? (
                    <FontAwesomeIcon icon={faEyeSlash} className="eye-icon" />
                  ) : (
                    <FontAwesomeIcon icon={faEye} className="eye-icon" />
                  )}
                </span>
              </div>
            </div>
            <div className="col-1">
              <div className="password-wrap">
                <Input
                  required
                  id="confirmPassword"
                  name="confirmPassword"
                  type={showPassword ? 'text' : 'password'}
                  autoComplete="off"
                  placeholder={t('input_placeholder_password_repeat', 'Repeat Password')}
                  value={this.state.confirmPassword}
                />
                <span onClick={this.togglePasswordShow} className="eye-password">
                  {showPassword ? (
                    <FontAwesomeIcon icon={faEyeSlash} className="eye-icon" />
                  ) : (
                    <FontAwesomeIcon icon={faEye} className="eye-icon" />
                  )}
                </span>
              </div>
            </div>
            <div className="col-1">
              <Button
                color={'green'}
                onClick={this.submitPassword}
                disabled={
                  !this.state.oldPassword.length || !this.state.newPassword.length || !this.state.confirmPassword.length
                }
              >
                {t('button_text_change_password', 'Change password')}
              </Button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

ChangePasswordForm.defaultProps = {
  changePasswordError: null,
  changePasswordSuccess: null,
};

ChangePasswordForm.propTypes = {
  t: PropTypes.func.isRequired,
  apiSubmitPassword: PropTypes.func.isRequired,
  isLoadingChangePassword: PropTypes.bool,
  changePasswordError: PropTypes.object,
  changePasswordSuccess: PropTypes.object,
  context: PropTypes.object.isRequired,
};

export default provideContext(ChangePasswordForm);
