import { apiConfig } from '../config/apiConfig';

const isRetailSnooOrder = lineItems => lineItems.some(lineItem => apiConfig.shopify.retailSnooVaraintIds.includes(lineItem.variant_id || lineItem.variantId));

const isSNOO = lineItem => apiConfig.shopify.retailSnooVaraintIds.includes(lineItem.variant_id || lineItem.variantId);

export {
  isRetailSnooOrder,
  isSNOO,
};
