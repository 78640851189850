import axios from 'axios';
import { routes, getApiRouteUrl } from '../routes';
import { constructAuthHeaders } from '../../utils/webAuth';

export const getLoggedInUser = async () => {
  try {
    const headers = await constructAuthHeaders();
    const response = await axios.get(getApiRouteUrl(routes.USERS_ME), headers);
    return { response };
  } catch (error) {
    return { error };
  }
}

export const updateUser = async (data) => {
  try {
    const headers = await constructAuthHeaders();
    const response = await axios.patch(getApiRouteUrl(routes.USERS_ME), data, headers);
    return { response };
  } catch (error) {
    return { error }
  }
}


export const getCorporateBenefits = async () => {
  try {
    const headers = await constructAuthHeaders();
    const response  = await axios.get(getApiRouteUrl(routes.USERS_CORPORATE_BENEFITS), headers)
    return { response };
  } catch(err) {
    return { err };
  }
}

export const createAnonymizationRequest = async () => {
  try {
    const headers = await constructAuthHeaders();
    const response  = await axios.post(getApiRouteUrl(routes.USERS_ANONYMIZATION),undefined, headers)
    return { response };
  } catch(err) {
    return { err };
  }
}

export const createDataExportRequest = async () => {
  try {
    const headers = await constructAuthHeaders();
    const response  = await axios.post(getApiRouteUrl(routes.PDS_DATA_EXPORT),undefined, headers)
    return { response };
  } catch(err) {
    return { err };
  }
}

export const checkLegal = async () => {
  try {
    const headers = await constructAuthHeaders();
    const response = await axios.get(getApiRouteUrl(routes.USERS_LEGAL_CHECK), headers);
    return { response };
  } catch (err) {
    return { err };
  }
}

export const acknowledgeLegal = async (data) => {
  try {
    const headers = await constructAuthHeaders();
    const response = await axios.post(getApiRouteUrl(routes.USERS_LEGAL_ACKNOWLEDGE), data, headers);
    return { response };
  } catch (err) {
    return { err };
  }
}

export const globalSignOut = async () => {
  try {
    const headers = await constructAuthHeaders();
    const response = await axios.post(getApiRouteUrl(routes.USERS_GLOBAL_SIGNOUT),undefined, headers);
    return { response };
  } catch (err) {
    return { err };
  }
}
