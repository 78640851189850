import { apiConfig } from '../config/apiConfig';
export default {
  HB_SHOP: apiConfig.web.shopUrl || 'https://happiestbaby.com',
  CUSTOMER_CARE_LINK: `https://${apiConfig.zendesk.subDomain}.com/hc/en-us/requests/new`,
  SLEEP_CONSULTATION_LINK: 'https://calendly.com/ccautomation-happiestbaby/30min',
  FAQ_LINK: { us: 'https://support.happiestbaby.com/hc/en-us', eu: 'https://support.happiestbaby.eu/hc/en-150', uk: 'https://support.happiestbaby.co.uk/hc/en-gb', au: 'https://support.happiestbaby.com.au/hc/en-us'},
  FEDEX_LOCATE: 'https://www.fedex.com/locate/',
  FEDEX_TRACKING_NUMBER: 'https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber=',
  UPS_TRACKING_NUMBER: 'https://www.ups.com/track?loc=en_US&requester=ST/?tracknum=',
  SCHEDULE_PICKUPS: 'https://wwwapps.ups.com/pickup/schedule?loc=en_US',
  SHIPPING_RETURNS: 'https://www.happiestbaby.com/pages/shipping-returns'
};
